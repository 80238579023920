import React from 'react';

import MainBody from './components/MainBody';
import './css/freelancer.scss';
import './css/custom.scss';

const App = () => {

  return (
      <MainBody/>
  );
};

export default App;
